.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.youtube-video {
  display: block; 
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 16 / 9;
  width: 70%;
}

.button {
  margin: auto;
  text-align: center;
  display: block;
  padding: 10px;
}

.button-image {
  border-radius: 10px;
  height: 57px !important;
  width: 229px !important;
}

.content {
  top: 10vh;
  position: relative;
  max-height: 80vh;
  bottom: 10vh;
  /* top: 50%;
  left:50%; */
  /* transform: translate(-20%, -20%);  */
}

.background {
  /* background-image: url("../../../assets/images/tal-betal-white-bg-logo-50-transparent-inverted-horizontal.png"), url("../../../assets/images/tal-betal-white-bg-logo-50-transparent.png"); */
  /* background-repeat: repeat, repeat; */
  min-height: 100%;
  background-color: black;
  /* background-repeat: space repeat, space repeat; */
}

/* .background::before {
  opacity: 0.6;
} */

.button-background {
  background-color: black;
  display: block;

}

.space {
  height: 1vh;
}

